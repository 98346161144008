import { trackingEvent } from '../../../analytics';
import { AMPLITUDE_SERVICE } from '../../../analytics/constants';
import { getBasicAmplitudEventProperties } from '../../../analytics/utils';
import { getUserRole } from '../../../utils/partialPermissionUtils';

export const trackEmptyState = ({ settings, url }) => {
  const userRole = getUserRole();
  trackingEvent(
    `${settings}_settings_empty_state`,
    {
      ...getBasicAmplitudEventProperties(),
      role: userRole,
      url_attempted: url
    },
    AMPLITUDE_SERVICE
  );
};
