import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Card } from 'antd';
import { openNotification } from '../../../utils';
import EventEmitter from 'react-native-eventemitter';
import { userService } from '../../../services/user.service';
import useConfigurationPermissions from '../../../hooks/useConfigurationPermissions';
import '../index.css';

import CardProfile from '../../../components/Settings/settingsProyect/CardProfile';
// Navegaciones
import SettingsCompany from '../Navbars/SettingsCompany';
import { withTranslation } from 'react-i18next';

// Cards config company
import CardGeneralCategory from '../../../components/Settings/settingsCompany/CardGeneral';
import CardUsersCompany from '../../../components/Settings/settingsCompany/CardUsers';
import CardTemplates from '../../../components/Settings/settingsCompany/CardTemplates';
import CardSubContracts from '../../../components/Settings/settingsCompany/CardSubContracts';
import { getSignedUser } from '../../../utils/userUtils';
import InvalidLinkLayout from '../../../layout/invalidLink';
import { NO_SETTINGS_ACCESS } from '../../../constants/access';
import { getPermissionsByCommand } from '../../../utils/partialPermissionUtils';
import { trackEmptyState } from '../utils';

function SettingsView(props) {
  const { t } = props;
  const stateProject = useSelector((state) => state.projectState);
  const [Router, setRouter] = useState(1);
  const [InfoUser, setInfoUser] = useState({});
  const [stateUpload, setStateUpload] = useState({ loading: false });
  const userStorage = getSignedUser();
  const permission = useConfigurationPermissions();

  useEffect(() => {
    const callback = (data) => {
      if (!stateProject.projectSelected) {
        const alertErrorMailExists = {
          title: t('settings.settings'),
          description: t('settings.project_no_selected'),
          type: 'error'
        };
        openNotification(alertErrorMailExists);
      } else {
        data.route(props.history);
      }
    };
    EventEmitter.on('changeMainRoute', callback);
    return () => {
      EventEmitter.removeListener('changeMainRoute', callback);
    };
  }, [stateProject.projectSelected]);

  useEffect(() => {
    window.Appcues.page();
  });

  // get data from  user
  useEffect(() => {
    const abortController = new AbortController();
    if (userStorage.id !== null) {
      userService
        .show(userStorage.id)
        .then((response) => {
          setInfoUser(response.user);
        })
        .catch((err) => console.log(err));
    }
    return function cleanup() {
      abortController.abort();
    };
  }, []);

  const renderWithPermission = () => {
    const canSeeCompanySettings =
      (permission.company === 'AC' || permission.company === 'V') &&
      getPermissionsByCommand('ORGANIZATION_SETTINGS', 'GENERAL', 'SETTINGS');
    if (canSeeCompanySettings) {
      return (
        <div className="background-image">
          <div className="grid-settings">
            <CardProfile
              permissions={permission}
              {...props}
              InfoUser={InfoUser}
              setInfoUser={setInfoUser}
              stateUpload={stateUpload}
              setStateUpload={setStateUpload}
            />
            <div
              style={{
                marginTop: '140px',
                marginLeft: '135px',
                height: '100%'
              }}>
              <Card className="card-shadow">
                <SettingsCompany setRouter={setRouter} Router={Router} t={t} />
                {Router === 1 && <CardGeneralCategory t={t} />}
                {Router === 2 && <CardUsersCompany t={t} />}
                {Router === 3 && <CardSubContracts t={t} />}
                {Router === 4 && <CardTemplates t={t} />}
              </Card>
            </div>
          </div>
        </div>
      );
    }

    trackEmptyState({
      settings: 'company',
      url: window.location.href
    });
    return <InvalidLinkLayout type={NO_SETTINGS_ACCESS} />;
  };

  /**
   * Render
   */
  return renderWithPermission();
}

export default withTranslation()(SettingsView);
