import React, { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import * as projectActions from '../../../redux/slices/projectSlice';

// => Assets
import './index.css';
import styles from './index.module.css';

// => Components
import { Animated } from 'react-animated-css';
import { Col, Row } from 'antd';
import ProductivityResouces from '../../../components/ProductivityResouces';
import ProductivityGrid from '../../../components/ProductivityGrid';
import ProductivtyActions from '../../../components/ProductivityActions';
import ProductityCalendar from '../../../components/ProductivityCalendar';

// Context
import ProductivityAssignProvider from '../../../Context/Productivty/Assign';

// ==> Services
import {
  sectorResourcesService,
  workerService,
  projectService
} from '../../../services';

import geoVictoriaLogo from '../../../assets/img/productivity/geoVictoria.png';

const userLang = navigator.language || navigator.userLanguage;
moment.locale(userLang);
const now = moment().subtract(1, 'days');
const defaultCalendarValue = now.clone();
defaultCalendarValue.add(-1, 'month');

const ProductivityAssign = (props) => {
  const { t } = props;

  const resourceSelectedTotal = useRef(0);

  // ==> States
  const [resources, setResources] = useState(null);
  const [crewWorkers, setCrewWorkers] = useState(null);
  const [geoVictoriaModule, setGeoVictoriaModule] = useState(null);
  const [totalResoucerSelected, setTotalResourcesSelected] = useState(0);
  const [workersAbsent, seWorkersAbsent] = useState(0);
  const [workersPresent, seWorkersPresent] = useState(0);
  const [dateInit, setDateInit] = useState(now);
  const [hoursPerDay, setHoursPerDay] = useState(0);

  // => Redux store
  const projectStore = useSelector((state) => state.projectState);
  const companyStore = useSelector(
    (state) => state.companyState.currentCompany
  );

  const dispatch = useDispatch();

  useEffect(() => checkHoursPerDay(), []);

  useEffect(() => {
    projectSettings();
    companySettings();
    updateData({ buildResourceTree: true });
  }, [projectStore, dateInit]);

  // ==> useEffect end

  const getProject = async () => {
    try {
      const { project } = await projectService.show(
        projectStore.projectSelected
      );
      return project;
    } catch (e) {
      console.error(e);
    }
  };

  const checkHoursPerDay = () => {
    hoursPerDayIfNotStored();
  };

  const hoursPerDayIfNotStored = async () => {
    const projectSelected = projectStore.allProjects.find(
      (project) => project.id === projectStore.projectSelected
    );

    if (!projectSelected.hoursPerDay) {
      const projectDB = await getProject();
      const allProjects = projectStore.allProjects.map((project) => {
        if (project.id == projectStore.projectSelected) {
          return { ...project, hoursPerDay: projectDB.hoursPerDay || 8 };
        }
        return { ...project };
      });
      dispatch(projectActions.setAllProjects(allProjects));
    }
  };

  const projectSettings = () => {
    const project = projectStore.allProjects.find(
      (project) => project.id == projectStore.projectSelected
    );
    setHoursPerDay(project.hoursPerDay);
  };

  const companySettings = () => {
    const geoVic = companyStore.geo_victoria_config;
    setGeoVictoriaModule(geoVic);
  };

  const getResources = async () => {
    const { sectorResources } =
      await sectorResourcesService.showBySectorWithWorkers(
        projectStore.sectorSelected
      );
    const response = sectorResources?.length ? sectorResources : undefined;
    return response;
  };

  const buildActivitiesToRender = async () => {
    const activityResources = await getResources();
    setResources(activityResources);
  };

  const getCrewWorkers = async () => {
    try {
      const month = dateInit.format('MM');
      const day = dateInit.format('DD');
      const year = dateInit.format('YYYY');
      const date = `${year}-${month}-${day}`;
      const { workersCrew, workersAbsent, workersPresent } =
        await workerService.getCrewWorkersByProject(
          projectStore.projectSelected,
          date
        );

      if (!workersCrew) {
        throw new Error('no workers');
      }

      setCrewWorkers(workersCrew);
      seWorkersPresent(workersPresent);
      seWorkersAbsent(workersAbsent);
    } catch (e) {
      setCrewWorkers([]);
      seWorkersPresent(0);
      seWorkersAbsent(0);
    }
  };

  const updateData = ({ buildResourceTree = false } = {}) => {
    setCrewWorkers(null);
    setResources(null);
    buildResourceTree && buildActivitiesToRender();
    getCrewWorkers();
  };

  const handleClick = () => {
    const itemMenuLookahead = document.querySelector('#menu>li:nth-child(5)');
    const itemMenuTakt = document.querySelector('#menu>li:nth-child(1)');
    itemMenuLookahead.classList.remove('ant-menu-item-selected');
    itemMenuTakt.classList.add('ant-menu-item-selected');
  };

  if (!geoVictoriaModule) {
    return (
      <div className={styles.missGeoVictoria}>
        <p>
          {t('productivity.missGeoVictoria')}
          <img
            src={geoVictoriaLogo}
            style={{ marginLeft: 10 }}
            width={150}
            alt="geovictoria__icon"
          />
        </p>
      </div>
    );
  }

  if (typeof resources == 'undefined') {
    return (
      <div className={styles.missGeoVictoria}>
        <p>
          {t('productivity.assign.missResources')} 😕{' '}
          <Link
            to={`/gantt/${projectStore.sectorSelected}/resources`}
            onClick={handleClick}>
            {t('productivity.assign.linkToCreateResources')}
          </Link>
        </p>
      </div>
    );
  }

  return (
    <Animated
      animationIn="fadeIn"
      animationOut="fadeOut"
      animationInDuration={500}
      animationOutDuration={500}
      isVisible={true}>
      <Row>
        <ProductivityAssignProvider>
          <Row className={styles.calendarContainer}>
            <Col
              className={`${styles.calendar} ${!resources && styles.loading}`}>
              {resources ? (
                <ProductityCalendar
                  dateInit={dateInit}
                  setDateInit={setDateInit}
                  defaultCalendarValue={defaultCalendarValue}
                />
              ) : null}
            </Col>
            <ProductivtyActions
              className={styles.actions}
              dateInit={dateInit}
              updateData={updateData}
              workersPresent={workersPresent}
              workersAbsent={workersAbsent}
              hoursPerDay={hoursPerDay}
              t={t}
            />
          </Row>
          <Row className={styles.assigmentSection}>
            <Col className={styles.col}>
              <div className={styles.resourcesTitle}>
                <h2 className={styles.sectionTitle}>
                  {t('master_plan.resources')}
                </h2>
                {totalResoucerSelected ? (
                  <p>
                    {' '}
                    ({totalResoucerSelected} {t('productivity.assign.selected')}
                    )
                  </p>
                ) : null}
              </div>
              <ProductivityResouces
                t={t}
                ref={resourceSelectedTotal}
                totalResoucerSelected={setTotalResourcesSelected}
                loadingStyle={styles.loading}
                resouceStyle={styles.resources}
                resources={resources}
                dateInit={dateInit}
              />
            </Col>
            <Col className={styles.col}>
              <ProductivityGrid
                dateInit={dateInit}
                hoursPerDay={hoursPerDay}
                updateData={updateData}
                loadingStyle={styles.loading}
                workersStyle={styles.workers}
                resouceStyle={styles.resourcesGrid}
                resources={resources}
                crewWorkers={crewWorkers}
              />
            </Col>
          </Row>
        </ProductivityAssignProvider>
      </Row>
    </Animated>
  );
};

export default withTranslation()(ProductivityAssign);
