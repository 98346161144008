import React, { useEffect } from 'react';
import EventEmitter from 'react-native-eventemitter';
import WeekReport from '../../../components/Report/WeekReport';
import { useParams } from 'react-router-dom';

export default function Report(props) {
  const { week, planificationday, year, start } = useParams();

  /** Similar to did mount */
  useEffect(() => {
    const callback = (data) => {
      data.route(props.history);
    };

    EventEmitter.on('changeMainRoute', callback);
    window.loader.show();
    return () => {
      EventEmitter.removeListener('changeMainRoute', callback);
    };
  }, []);
  // <General/>
  return (
    <div className="week-report-container" style={{ background: '#FFFFFF' }}>
      <WeekReport
        year={year}
        week={year == 2021 ? week - 1 : week}
        planificationday={planificationday == 0 ? -1 : planificationday - 1}
        start={start}
      />
    </div>
  );
}
