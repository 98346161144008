import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import './settingsPlugin.scss';
import useConfigurationPermissions from '../../../hooks/useConfigurationPermissions';
import { withTranslation } from 'react-i18next';
import CardProfile from '../../../components/Settings/settingsProyect/CardProfile';
import CardSettingPlugin from '../../../components/Settings/settingsPlugin/CardSettingPlugin';
import { useSelector } from 'react-redux';
import { openNotification } from '../../../utils';
import EventEmitter from 'react-native-eventemitter';
import { useHistory } from 'react-router-dom';
import { getSignedUser } from '../../../utils/userUtils';

function SettingsPlugin({ t, history }) {
  const stateProject = useSelector((state) => state.projectState);
  const permission = useConfigurationPermissions();
  const [infoUser, setInfoUser] = useState({});
  const [uploadState, setUploadState] = useState({ loading: false });
  const redirect = useHistory();
  useEffect(() => {
    const userStorage = getSignedUser();
    setInfoUser(userStorage);
    if (permission.integrations != 'AC') redirect.push('/settings/user');
  }, []);

  useEffect(() => {
    const callback = (data) => {
      if (!stateProject.projectSelected) {
        const alertErrorMailExists = {
          title: t('settings.settings'),
          description: t('settings.project_no_selected'),
          type: 'error'
        };
        openNotification(alertErrorMailExists);
      } else {
        data.route(history);
      }
    };
    EventEmitter.on('changeMainRoute', callback);
    return () => {
      EventEmitter.removeListener('changeMainRoute', callback);
    };
  }, [stateProject.projectSelected]);

  return (
    <div className="background-image">
      <div className="grid-settings">
        <CardProfile
          permissions={permission}
          t={t}
          history={history}
          InfoUser={infoUser}
          setInfoUser={setInfoUser}
          stateUpload={uploadState}
          setStateUpload={setUploadState}
        />
        {permission.integrations != 'SA' ? (
          <div className="card-container">
            <Card className="card-shadow">
              <CardSettingPlugin t={t} />
            </Card>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default withTranslation()(SettingsPlugin);
